import { getRequest, postJsonRequest } from '@/api/axios'

//获取栏目信息
export const getQuotes = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/column-list', params)
}

export const getQuotesList = (params) => {
  return getRequest('/blade-integrate/openapi/integrateinformation/list', params)
}

export const getNews = (params) => {
  return postJsonRequest('/blade-integrate/openapi/integrateinformation/listByType', params)
}

export const getpricotype = (params) => {
  return getRequest('/blade-integrate/openapi/integrateprice/priceListByType', params)
}

export const getdetail = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/detail', params)
}