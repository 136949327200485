<template>
  <div>
    <div class="container">
      <div class="head flex">
        <h1 @click="zixun()">{{ aboutname }}</h1>
        <p v-if="aboutname != columnName">|</p>
        <h1 v-if="aboutname != columnName" class="color2c">{{ columnName }}</h1>
        <p>
          <span @click="son2()">首页</span>
          <span v-if="aboutname != columnName" @click="zixun()">{{
            aboutname
          }}</span>
          <span>{{ columnName }}</span>
        </p>
      </div>
      <div class="bottom flex">
        <div class="left">
          <div class="prico">
            <h1 class="prtitle">全国地区</h1>
            <!-- <div class="flex prico-area">
              <ul class="flex">
                <li>云城区</li>
                <li>新兴县</li>
                <li>郁南县</li>
                <li>云安县</li>
                <li>罗定</li>
              </ul>
              <p>更多</p>
            </div> -->
            <div>
              <div class="bor">
                <ul class="flex pricco-conent">
                  <li v-for="item in prico.slice(0, 3)" :key="item.id">
                    <div>{{ item.productName }}</div>
                    <h1>{{ item.price }}</h1>
                    <p class="green" v-if="item.trend == 'same'">
                      --<span v-if="item.productType == 1">元/公斤</span
                      ><span v-if="item.productType != 1">元/吨</span>
                    </p>
                    <p class="red" v-if="item.trend == 'up'">
                      ↑{{ item.diffYesterday
                      }}<span v-if="item.productType == 1">元/公斤</span
                      ><span v-if="item.productType != 1">元/吨</span>
                    </p>
                    <p class="green" v-if="item.trend == 'down'">
                      ↓{{ -item.diffYesterday
                      }}<span v-if="item.productType == 1">元/公斤</span
                      ><span v-if="item.productType != 1">元/吨</span>
                    </p>
                  </li>
                  <li v-for="item in prico.slice(3)" :key="item.id">
                    <div>{{ item.productName }}</div>
                    <h1>{{ item.price }}:1</h1>
                    <p class="green" v-if="item.trend == 'same'">--</p>
                    <p class="red" v-if="item.trend == 'up'">
                      ↑{{ item.diffYesterday }}
                    </p>
                    <p class="green" v-if="item.trend == 'down'">
                      ↓{{ -item.diffYesterday }}
                    </p>
                  </li>
                </ul>
              </div>
              <!-- <div>
                <li></li>
              </div> -->
            </div>
            <div></div>
          </div>
          <div class="list">
            <ul>
              <li
                class="flex"
                v-for="item in list"
                :key="item.id"
                @click="son(item)"
              >
                <div><img :src="item.icon" /></div>
                <div>
                  <p class="infor">{{ item.title }}</p>
                  <div class="flex source">
                    <p>
                      来源:{{
                        item.sourceValue == ""
                          ? item.websetName
                          : item.sourceValue
                      }}
                    </p>
                    <p>
                      发布时间:<span>{{ item.releaseTime.slice(0, 16) }}</span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <h1>即时热点<span>HOT</span></h1>
            <ul>
              <li
                v-for="(item, idx) in hotList"
                :key="item.id"
                @click="jump(item)"
              >
                <i
                  ><span>{{ idx + 1 }}</span></i
                >
                {{ item.title }}
              </li>
            </ul>
          </div>
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
              <img :src="item.picturePath" style="width:100%;" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="Pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="cur"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
          background
        >
        </el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { getBanner } from "@/api/banner";
import {
  getQuotes,
  getQuotesList,
  getNews,
  getpricotype
} from "@/api/agricuinfotThree";
import { getdetail } from "../../../api/home1";
import Footer from "../../components/footer.vue";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import "swiper/js/swiper.js";
export default {
  components: { Footer },
  data() {
    return {
      Img: [],
      label: [],
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
      all: 10, //总页数
      cur: 1, //当前页码
      totalPage: 0, //当前条数
      pageSize: 10,
      list: "",
      hotList: "",
      aboutname: "",
      columid: "",
      paths: "",
      columnName: "",
      columnNameId: "",
      baner: "",
      bannerImg: "",
      prico: ""
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.cur = val;
      getQuotesList({
        columnId: this.columid,
        size: this.pageSize,
        current: this.cur
      }).then(res => {
        this.list = res.data.records;
        this.all = res.data.pages;
        this.cur = res.data.current;
        this.totalPage = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      getQuotesList({
        columnId: this.columid,
        size: this.pageSize,
        current: this.cur
      }).then(res => {
        this.list = res.data.records;
        this.all = res.data.pages;
        this.cur = res.data.current;
        this.totalPage = res.data.total;
      });
    },
    getbanner() {
      getBanner({
        columnId: this.columid,
        type: 1
      }).then(res => {
        if (res.data.length == 0) {
          getBanner({
            columnId: this.columid,
            type: 2
          }).then(res => {
            this.bannerImg = res.data;
          });
        } else {
          this.bannerImg = res.data;
        }
      });
    },
    mouseOver() {
      clearInterval(this.timer);
    },
    // mouseLeave() {
    //   this.runInv()
    // },
    dataList(idx) {
      getQuotesList({
        columnId: this.columid,
        size: 10,
        current: idx
      }).then(res => {
        this.list = res.data.records;
        this.all = res.data.pages;
        this.cur = res.data.current;
        this.totalPage = res.data.total;
      });
    },
    getcolum() {
      getQuotes({
        parentId: this.columid
      }).then(res => {
        this.dataList(this.cur.toString());
      });
    },
    gethot() {
      getNews({
        type: 2,
        columnId: this.columid
      }).then(res => {
        this.hotList = res.data.records;
      });
    },
    // zixun(){
    //   this.$router.push({
    //     path: this.paths,
    //     query: {
    //       columid: this.columid,
    //     }
    //   })
    // },
    getList() {
      getdetail({
        id: this.$route.query.columid
      }).then(res => {
        this.columnName = res.data.columnName;
        this.aboutname = res.data.columnName;
        this.paths = res.data.parentAccessPath;
      });
      getQuotesList({
        columnId: this.columid
      }).then(res => {
        this.baner = res.data.records;
        for (let i = 0; i < 3; i++) {
          this.Img.push(res.data.records[i].icon);
          this.label.push(res.data.records[i].title);
        }
      });
    },
    son(item) {
      let routerJump = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          // columnNameId: this.columnNameId,
          titleId: item.id
          // columid:this.columid
        }
      });
      window.open(routerJump.href, "_blank");
    },
    son1() {
      let routerJump = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          // columnNameId: this.columnNameId,
          titleId: this.baner[this.currentIndex].id
          // columid:this.columid
        }
      });
      window.open(routerJump.href, "_blank");
    },
    jump(item) {
      let routerJump = this.$router.resolve({
        path: "/domesticDetails",
        query: {
          // columnNameId: this.columnNameId,
          titleId: item.id
          // columid:this.columid
        }
      });
      window.open(routerJump.href, "_blank");
    },
    son2() {
      this.$router.push("/home");
    },
    getprico() {
      getpricotype({}).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].productType > 1 && res.data[i].productType != i) {
            var obj = res.data[i];
            res.data.splice(i, 1);
            res.data.push(obj);
          }
        }
        this.prico = res.data;
      });
    }
  },
  mounted() {
    this.columid = this.$route.query.columid;
    // this.columnNameId=this.$route.query.columnNameId
    this.getbanner();
    var swiper = new Swiper(".swiper-container", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 30,
      observer: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      autoplay: {
        delay: 2000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      }
    });
    // this.runInv()
    this.getcolum();
    this.gethot();
    this.getList();
    this.getprico();
    this.dataList();
  }
};
</script>

<style scoped>
.bottom {
  height: 100%;
}
.left {
  width: 60%;
  height: 100%;
}
.right {
  max-width: 35%;
  height: 100%;
  margin-left: 5%;
}
.ul {
  height: 30px;
  padding-right: 20px;
  justify-content: space-between;
}
.ul .li {
  list-style: none;
  margin: 0 10px;
  width: 10px;
  height: 10px;
  /* margin-top: 10px; */
  text-align: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  border-radius: 50%;
  border: 1px solid #fff;
  float: left;
}
.banner {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.banner img {
  width: 100%;
  height: 478px;
  display: block;
}
.banner .page {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 780px;
  align-items: center;
}
.banner .page .ul {
  float: right;
  padding-top: 10px;
}
.current {
  background-color: #fff;
  border: none;
  width: 12px;
  height: 12px;
}
.font {
  text-align: left;
  color: #fff;
  float: left;
  line-height: 40px;
  padding-left: 10px;
}
.list li div:nth-child(2) {
  width: 80%;
}
.list img {
  width: 130px;
  height: 90px;
  margin-right: 30px;
}
.list li {
  position: relative;
  border-bottom: 1px solid #bfbfbf;
  padding: 10px 0;
  font-size: 16px;
  cursor: pointer;
}
.list p {
  width: 100%;
}
.list .infor {
  margin: 10px 0;
  color: #000;
}
.list .source {
  position: absolute;
  bottom: 10px;
  color: #7f7f7f;
}
.right .top h1 {
  margin: 10px 0 30px;
  color: #51d5c3;
  font-weight: bold;
}
.right .top h1 span {
  color: #c00000;
  margin-left: 10px;
}
.right .top li {
  margin: 12px 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right .top li:hover {
  color: #51d5c3;
}
.right .top li span {
  background-color: #51d5c3;
  display: inline-block;
  padding: 0 5px;
  position: relative;
  top: -3px;
  left: -3px;
  color: #fff;
}
.right .top li i {
  border: 1px solid #51d5c3;
  margin-right: 10px;
}
.ad img {
  margin-top: 20px;
  width: 100%;
  height: 240px;
  cursor: pointer;
}
.Pagination {
  margin: 40px auto;
  margin-top: 50px;
}
.Pagination ul {
  justify-content: center;
}
.Pagination li:first-child > a {
  margin-left: 0px;
}
.Pagination a {
  border: 1px solid #000;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.Pagination a:hover {
  background-color: #eee;
}
.Pagination .active a {
  color: #fff;
  cursor: default;
  background-color: #51d5c3;
  border-color: #51d5c3;
}
.Pagination i {
  font-style: normal;
  color: #51d5c3;
  margin: 0px 4px;
  font-style: 12px;
}
.head {
  border-top: 3px solid #51d5c3;
  width: 100%;
  padding: 20px 0;
  align-items: center;
  justify-content: left;
}
.head h1 {
  margin: 0 10px;
  font-weight: bold;
  color: #51d5c3;
}
.color2c {
  color: #2c2c2c !important;
}
.head span {
  color: #797979;
}
.head span + span::before {
  content: ">";
  margin: 0 5px;
  color: #797979;
}
.list .infor:hover {
  color: #51d5c3;
}
.container p span:nth-child(1),
.container p span:nth-child(2) {
  cursor: pointer;
}
.container p span:hover {
  color: #51d5c3;
}
.container p span:last-child:hover {
  color: #797979;
}
.prico-area {
  padding: 10px 10px;
  justify-content: space-between;
  border: 1px solid #51d5c3;
  margin: 20px 0 5px;
}
.prico-area ul li {
  margin: 0 10px;
}
.pricco-conent {
  justify-content: space-around;
  text-align: center;
  padding: 20px 0;
}
.pricco-conent li:hover {
  border: #51d5c3 solid 1px;
}
.pricco-conent li {
  padding: 10px 5px;
}
.pricco-conent li h1 {
  color: #db573e;
  margin: 25px 0;
  font-weight: bold;
}
.pricco-conent li div {
  color: #fff;
  background-color: #51d5c3;
  border-radius: 20px;
  padding: 5px 10px;
}
.pricco-conent li p {
  font-weight: bold;
}
.red {
  color: #db573e;
}
.green {
  color: #4a9e28;
}
.pricco-conent li p span {
  color: #333;
}
.bor {
  border: 1px solid #51d5c3;
  padding: 0 3px;
}
.prtitle {
  margin-bottom: 10px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #51d5c3;
}
/deep/.el-pagination.is-background .el-pager .number:hover {
  color: #51d5c3;
}
.banner {
  position: relative;
  height: 100%;
  width: 100%;
  background: #fff;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  margin-bottom: 11px;
}
.banner img {
  width: 100%;
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
/deep/ .el-carousel__indicators--outside {
  display: none;
}
/deep/.el-carousel__arrow--left {
  display: none !important;
}
/deep/.el-carousel__arrow--right {
  display: none !important;
}
</style>
